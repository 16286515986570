import React from "react";
import { Link } from "gatsby";
import { Container, Button } from "react-bootstrap";
import Section from "../components/Section";
import PageWrapper from "../components/PageWrapper";
import { Title, Text } from "../components/Core";

const FourOhFour = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          isFluid: true,
        }}
      >
        <Section style={{minHeight: '90vh'}}>
          <div className="pt-5"></div>
          <Container>
            <div className="text-center">
              <div>
                <Title variant="hero">Page Not Found</Title>
                <Text>
                  The page you are looking for is not available or doesn’t
                  <br className="d-none d-md-block" /> exist on this website!
                </Text>
              </div>
              <div className="mt-5">
                <Link to="/">
                  <Button>Back to Home</Button>
                </Link>
              </div>
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
};

export default FourOhFour